<template>
  <v-container>
    <v-row justify="space-between">
      <v-col>
        <v-select
          v-model="groupFilter"
          :items="groups"
          :disabled="loading"
          item-text="name"
          item-value="id"
          label="Grupo"
          placeholder="Todos los Grupos"
          prepend-inner-icon="fas fa-filter"
          single-line
          outlined
          dense
          clearable
          @change="() => {
            this.options.page=1;fetch();
          }"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          @keyup="searchTimeOut"
          :disabled="loading"
          label="Buscar"
          append-icon="fas fa-search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="end">
    </v-row>
      <v-data-table
          :headers="headers"
          :items="costs"
          :items-per-page="5"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItems"
          loading-text="Cargando Datos"
          :search="search"
          @update:options="fetch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }"
        >
        <template v-slot:item.name="{ item }">
          {{item.name}}
          <v-icon v-if="item.has_dimension" class="ml-2 info--text" x-small>fas fa-ruler-combined</v-icon>
          <span v-if="item.has_dimension" class="caption ml-2" >[Al:{{item.height}} x An:{{item.width}}]</span>
        </template>
        <template v-slot:item.unit_name="{ item }">
          <v-chip  class="chip" outlined label>{{item.unit_name}}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn fab success small>
            <v-icon class="success--text" @click.stop="add(item)">fas fa-plus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
</v-container>
</template>
<script>
import {fetchCost,fetchGroups,fetchUnits} from '@/api/products'
import notifications from '@/utils/notifications'
export default {
  name:'AddCost',
  components:{},
  props:{
    is_supply:{
      type:Boolean,
      default:false
    }
  },
  data:()=>({
    costs:[],
    search:'',
    timer:'',
    loading:false,
    options:{},
    totalItems:0,
    search:'',
    groups:[],
    units:[],
    groupFilter:'',
    headers: [
      {text: 'Descripción',value:'name',class: 'table-header'},
      { text: 'Unidades',value:'unit_name',class: 'table-header'},
      { text: 'Grupo', value:'group_name',class: 'table-header'},
      { text: '',value:'actions'}
    ],
  }),
  methods:{
    add(item){
      this.$emit('add',item)
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(!this.groupFilter) this.groupFilter=''
      if(sortBy[0]=='group_name'){
        sort = 'group__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else{
        sort = sortBy[0]
      }
      const query=`search=${this.search}&ordering=${sortDesc[0]?`${sort}`:`-${sort}`}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&is_supply=${this.is_supply}&group=${this.groupFilter}&is_active=true`
      await fetchCost(query)
      .then((data)=>{
        this.totalItems=parseInt(data.count)
        this.costs=data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error en carga de datos '+err)
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.options.page=1
          this.fetch()
      }, 1000);
    },
  },
  async mounted(){
    this.loading=true
    this.fetch()
    this.groups=await fetchGroups(this.is_supply)
    this.units=this.$store.getters.getUnits
    this.loading=false
  }
}
</script>

<style scoped>
.chip{
  width: 4rem !important;
  justify-items: center !important;
}
</style>