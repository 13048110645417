import axios from "axios"


export async function fetchPostCostWorkOrders(query) {
    return await axios.get(
            `reports/post-cost?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getPostCostWorkOrders(id) {
    return await axios.get(`reports/post-cost/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchProductionProducts(created_after = '', created_before = '') {
    return await axios.get(
            `reports/products?created_after=${created_after}&created_before=${created_before}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchSupplyOrders(query= '') {
    return await axios.get(
            `reports/supply-orders?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e  => {if(e.response.data.error) throw e.response.data.error})
}

export async function fetchInvoicedProducts(query) {
    return await axios.get(
            `reports/invoices-by-product?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchInvoicedDetailByProducts(query) {
    return await axios.get(
            `reports/invoices-detail?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export const fetchSalesDetailByProducts = async (query) => {
    return await axios.get(
            `reports/products?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export const fetchWorkOrdersMoney = async (query) => {
    return await axios.get(
            `reports/work-order-money?${query}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}