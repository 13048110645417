<template>
<v-container>
  <div class="d-flex justify-center align-items-center mt-6" v-if="loadingInfo">
    <v-progress-circular
      indeterminate
      color="success"
      class="mr-2"
    />
  </div>
  <div v-else>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(save)">
      <v-row class="mb-10 justify-space-between">
        <v-col cols="12" md="6">
          <h1 class="text text-h4 titulo">{{id ? 'Actualizar Producto':'Crear Producto'}}</h1>
        </v-col>
        <v-col class="text-end" align-self="center" cols="12" md="6">
          <v-switch
          class="d-flex d-inline-flex mr-6"
          inset
          v-model="status"
          :color="status ? 'success':'error'"
          :label="status ? 'Activo':'Inactivo'"
          ></v-switch>
          <v-btn :loading='isLoading' type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
          <v-btn fab small @click="$router.push('/products')" class="ml-2"><v-icon>fas fa-chevron-left</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-space-between">
        <v-col cols="12" md="4">
          <v-select
            :loading="families.length==0"
            v-model="family"
            :items="families"
            item-text="name"
            item-value="id"
            label="Familia"
            prepend-icon="family_restroom"
            placeholder="Asignar una familia"
            single-line
            outlined
            clearable
            dense
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            :loading="lines.length==0"
            v-model="line"
            :items="lines"
            item-text="name"
            item-value="id"
            label="Familia"
            prepend-icon="mdi-select-group"
            placeholder="Asignar una Línea"
            single-line
            outlined
            clearable
            dense
          />
        </v-col>
        <v-col cols="12" md="4">
          <customer-complete2 prependIcon="fas fa-city" :dense="true" v-model="customer" :customer_name="customer_name"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Código'
            v-model='sku'
            prepend-icon="fas fa-barcode"
            outlined
            :error-messages="errors"
            dense
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Nombre'
            v-model='name'
            prepend-icon="fas fa-tag"
            outlined
            dense
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <div>
            <ValidationProvider name="Precio" rules="required" v-slot="{ errors }">
              <v-text-field
                label='Precio'
                type="number"
                prepend-icon="fas fa-tags"
                v-model="price"
                outlined
                dense
                :disabled="is_margin"
                :error-messages="errors"
                :append-icon="sell_unit_m2?'square_foot':'view_comfy'"
                @click:append="sell_unit_m2=!sell_unit_m2"
              >
              </v-text-field>
            </ValidationProvider>
            <div class="d-flex">
              <v-checkbox
              v-model="is_margin"
              label="Precio por margen"
              
              hide-details
              dense
              @change="is_margin?price=0:price=price"
              ></v-checkbox>
              <v-text-field
              v-show="is_margin"
              label='Margen (%)'
              type="number"
              min="0"
              v-model='margin_price'
              hint="Porcentaje de margen"
              persistent-hint
              outlined
              dense
              ></v-text-field>
            </div>
            
          </div>
          </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <ValidationProvider name="Ancho" rules="required" v-slot="{ errors }">
            <v-text-field
            label='Ancho'
            type="number"
            v-model='width'
            prepend-icon="fas fa-ruler-horizontal"
            hint="Centímetros"
            persistent-hint
            outlined
            dense
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <ValidationProvider name="Alto" rules="required" v-slot="{ errors }">
            <v-text-field
            type="number"
            label='Alto'
            v-model='height'
            prepend-icon="fas fa-ruler-vertical"
            hint="Centímetros"
            persistent-hint
            outlined
            dense
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="4">
          <number-label 
          icon="fas fa-ruler-combined"
          title="Área Metros Cuadrados"
          >{{parseFloat(width)*parseFloat(height)*0.0001 |number('0.000')}}
          </number-label>
        </v-col>
      </v-row>
    </v-form>
    </ValidationObserver>
    <v-row justify="center">
      <v-col class="text-center">
        <v-card  class="pa-4">
          <v-card-title>
            <v-row justify="space-between">
              <div>
                <v-icon class="mr-2">fas fa-cogs</v-icon>Procesos
              </div>
            <v-btn color='success' class="mb-2" @click="addingProcess=true">
            <v-icon class="mr-2">add</v-icon>
              Proceso
            </v-btn>
            </v-row>
          </v-card-title>
          <div>
            <v-simple-table v-if="product_receta.length>0">
              <thead>
                <tr>
                  <th class="table-header">
                    Item
                  </th>
                  <th class="table-header">
                    Nombre
                  </th>
                  <th class="table-header">
                    Valor
                  </th>
                  <th class="table-header text-center">
                    Unidad
                  </th>
                  <th>
                    
                  </th>
                </tr>
              </thead>
              <draggable v-model="product_receta" draggable=".recipe-row" tag="tbody">
                <tr v-for="item in product_receta" :key="product_receta.indexOf(item)" class="recipe-row">
                  <td>
                    {{product_receta.indexOf(item) + 1}}
                  </td>
                  <td class="text-start">
                    {{`[${item.id}] ${item.name}`}}
                  </td>
                  <td>
                    {{item.price | currency('$',0)}}
                  </td>
                  <td>
                    <v-chip outlined label class="chip">
                      {{item.unit_name}}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn text @click="deleteProcess(item)" small class="error--text"><v-icon>delete</v-icon></v-btn>
                  </td>
                </tr>
              </draggable>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="text-center">
        <v-card class="pa-4">
          <v-card-title>
            <v-row justify="space-between">
              <div>
                <v-icon class="mr-2">fas fa-calculator</v-icon>Materiales
              </div>
            <v-btn class="mb-2" color="success" @click="addingSupply=true">
                <v-icon class="mr-2">fas fa-plus</v-icon>
                Material
            </v-btn>
            </v-row>
          </v-card-title>
          <div>
            <v-simple-table v-if="supplies.length>0">
              <thead>
                <tr>
                  <th class="table-header">
                    Item
                  </th>
                  <th class="table-header">
                    Nombre
                  </th>
                  <th class="table-header text-center">
                    Valor
                  </th>
                  <th class="table-header text-center">
                    Cant.
                  </th>
                  <th class="table-header text-center">
                    Unidad
                  </th>
                  <th>
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in supplies" :key="item.id" class="recipe-row">
                  <td>
                    {{index + 1}}
                  </td>
                  <td class="text-start">
                    <div class="d-block">
                      {{item.name}}
                      <v-icon
                        v-if="item.has_dimension" 
                        x-small class="info--text ml-4">
                        fas fa-ruler-combined
                      </v-icon>
                    </div>
                    <div v-if="item.has_dimension" class="d-block info--text text-caption">
                      An:{{item.width}} x Al: {{item.height}}
                    </div>
                  </td>
                  <td class="text-end">
                    {{item.price | currency('$',0)}}
                  </td>
                  <td class="text-center" width="125px">
                    <v-text-field solo dense hide-details v-model="item.qty" type="number"/>
                  </td>
                  <td>
                    <v-chip outlined label class="chip">
                      {{item.unit_name}}
                    </v-chip>
                  </td>
                  <td>
                    <v-btn text @click="deleteSupply(item)" small class="error--text"><v-icon>delete</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <v-switch
  class="d-flex d-inline-flex mr-6"
  inset
  dense
  v-model="logistic"
  :color="logistic ? 'primary':'error'"
  :label="logistic ? 'Logistico':'Produccion'"
  ></v-switch>

  <v-dialog v-model="addingProcess" max-width="800">
    <v-card  class='pa-8'>
      <v-card-title>
        <v-icon class="mr-2">fas fa-cogs</v-icon>
        <div class="text text-h4 titulo">Agregar Proceso</div>
      </v-card-title>
      <add-cost @add="addProcess" :is_supply="false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addingSupply" max-width="800">
    <v-card class='pa-8'>
      <v-card-title>
        <v-icon class="mr-2">fas fa-calculator</v-icon>
        <div class="text text-h4 titulo">Agregar Material</div>
      </v-card-title>
      <add-cost @add="addSupply" :is_supply="true"/>
    </v-card>
  </v-dialog>
</v-container>
</template>
<script>
import {fetchFamily, fetchLines, fetchGroups, getProduct,createProduct,updateProduct,getCost} from '@/api/products'
import notifications from '@/utils/notifications'
import AddCost from '@/components/products/costs/AddCost'
import linealUnits from '@/utils/linealUnits.json'
import CustomerComplete2 from '@/components/customers/CustomerComplete2'
import draggable from 'vuedraggable'
import UnitInput from '@/components/products/products/UnitInput'
import NumberLabel from '@/components/utils/NumberLabel'
import {calcSheets} from '@/utils/productCost'

export default {
  name:'ProductCreateUpdate',
  components: {CustomerComplete2,AddCost,draggable,UnitInput,NumberLabel},
  data:()=>({
    id:-1,
    sku:'',
    name:'',
    width: 0,
    height:0,
    price:0,
    status:true,
    sell_unit_m2:true,
    customer:'',
    customer_name:'',
    family:'',
    line:'',
    logistic:false,
    is_margin:false,
    margin_price:0,
    receta:[],
    families:[],
    lines:[],
    product_receta:[],
    supplies:[],
    addingProcess:false,
    addingProcess:false,
    addingSupply:false,
    isLoading:false,
    linealUnits:[],
    linealUnit:1,
    loadingInfo:false,
  }),
  async created(){
    this.families = await fetchFamily()
    this.lines = await fetchLines()

    if(this.$route.params.product){
      const product_id = this.$route.params.product
      await getProduct(product_id).then(data=>{
        this.loadingInfo = true
        this.id = data.id
        this.sku = data.sku
        this.name = data.name
        this.width = data.width
        this.height = data.height
        this.price = data.price
        this.status = data.status
        this.sell_unit_m2 = data.sell_unit_m2
        this.customer = data.customer
        this.customer_name = data.customer_name
        this.family = data.family
        this.line = data.line
        this.receta = data.receta
        this.product_receta = data.product_receta
        this.supplies = data.product_supplies
        this.linealUnit = data.lineal_unit
        this.logistic = data.logistic
        this.is_margin = data.is_margin
        this.margin_price = data.margin_price
      })
      .catch(err=>{
        notifications.showError(err.message)
      })
      .finally(()=>{
        this.loadingInfo = false
      })
      
    }
  },
  watch:{
    width(){
      this.updateSupplySheetQty()
    },
    height(){
      this.updateSupplySheetQty()
    },
  },
  methods:{
    updateSupplySheetQty(){
      const dimension_supplies=this.supplies.filter(s=>s.has_dimension).forEach(s=>{
        s.qty=calcSheets({width:this.width, height:this.height},s)
      })
    },
    addProcess(item){
      this.product_receta.push(item)
      this.addingProcess=false,
      this.addingSupply=false
    },
    deleteProcess(item){
      const index = this.product_receta.indexOf(item);
      this.product_receta.splice(index, 1);
    },
    addSupply(item){
      //Revisa si el objecto es vacío para poder usar la función push. 
      if(Object.keys(this.supplies).length === 0) this.supplies = []
      const new_data={...item,qty:item.has_dimension?calcSheets({width:this.width,height:this.height},item):1}
      this.supplies.push(new_data)
      this.addingProcess=false,
      this.addingSupply=false
    },
    deleteSupply(item){
      const index = this.supplies.indexOf(item);
      this.supplies.splice(index, 1);
    },
    async save(){
      console.log('save')
      //Construyendo el objeto producto
      const product={
        name:this.name,
        sku:this.sku,
        width:this.width,
        height:this.height,
        price:this.price,
        status:this.status,
        logistic:this.logistic,
        is_margin:this.is_margin,
        margin_price:this.margin_price,
        sell_unit_m2:this.sell_unit_m2,
        receta: this.product_receta.map(r=> {
          return r.id}),
        supplies: this.supplies.map(s=>{
          return {
            id: s.id,
            qty: s.qty
          }
        })
      }
        //condicionales para poder crear productos con campos vacíos
        if(this.id>0) product.id=this.id
        if(this.customer) product.customer = this.customer.id
        if(this.family) product.family = this.family
        if(this.line) product.line = this.line
      this.isLoading=true
      
      //Si id es mayor que 0 el productro si existe.
      if(this.id>0){
        await updateProduct(product)
        .then(()=>{
          this.$router.push('/products')
          notifications.showSuccess('Producto '+ this.name + ' Actualizado')
        })
        .catch((e)=>{
          notifications.showError('Error Actualizando el Producto' + e)
        })
      }
      else{
        //Crea un producto nuevo
        await createProduct(product)
        .then(()=>{
          this.$router.replace('/products')
          notifications.showSuccess('Producto '+ this.name + ' Creado')
        })
        .catch((e)=>{
          notifications.showError('Error Creando Producto' + e)
        })
      }
      this.isLoading=false
    },
  },
}
</script>

<style scoped>
.recipe-quantity{
      width: 7rem;    
}
.recipe-row{
  cursor: move;
}
.chip{
  width: 4rem !important;
  justify-items: center !important;
}


</style>