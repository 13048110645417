<template>
  <v-card elevation-1 :loading="loading">
    <v-app-bar class="mb-2 mt-2" :color="dragEnable?'primary':'light-blue'" dark> 
      <v-progress-circular
      indeterminate
      color="white"
      v-if="loading"
      class="mr-2"
    ></v-progress-circular>
      <v-toolbar-title v-if="!loading">{{dragEnable?'Planificación':'Lista'}} de <strong>{{machine.name}}</strong> </v-toolbar-title>
     <v-spacer></v-spacer>
      <v-chip v-if="dragEnable && !loading"  :color="dragEnable?'primary':'light-blue'">

          Capacidad: <strong class="ml-2">{{parseInt(machine.capacity)}}</strong> 
          <span class='ml-2'>{{machine.production_unit_m2?'mts2':'unidades'}}</span>
          / hora
        </v-chip>
        <v-chip v-if="dragEnable && !loading"  :color="dragEnable?'primary':'light-blue'">
          <v-icon class="mr-2" small>fas fa-clock</v-icon>
          {{machine.hours_per_day}} horas / día
        </v-chip>
        <v-spacer></v-spacer>
      <export-machine-planner :secuence="secuence" :disabled="loading"/>
      <v-btn  v-if="dragEnable && !loading" text icon color="white" @click="getMachineData" :disabled="loading" title="Recargar">
        <v-icon>fas fa-sync-alt</v-icon>
      </v-btn>
      <v-btn v-if="dragEnable && !loading" text icon color="white" @click="savePlanner" :disabled="loading" :loading="saving" title="Guardar planificacion">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
   



    </v-app-bar>

    <br/>
    <v-toolbar flat>
      <v-toolbar-title>
        <v-chip v-if="dragEnable"  class="ml-2" outlined label :disabled="loading" title="Total planificado">
          <v-icon class="mr-2">{{machine.production_unit_m2?'square_foot':'view_comfy'}}</v-icon>
           {{machine.production_unit_m2?Math.ceil(this.totalOnQueue.to_complete_m2):this.totalOnQueue.to_complete_qty}}
        </v-chip>
        <v-chip  v-if="dragEnable"  outlined label class="ml-2" :disabled="loading" title="Total horas">
          <v-icon class="mr-2" small>fas fa-clock</v-icon>{{machine.production_unit_m2?Math.ceil(this.totalOnQueue.to_complete_m2/machine.capacity):Math.ceil(this.totalOnQueue.to_complete_qty/machine.capacity)}}
        </v-chip>
        <v-chip v-if="dragEnable"  outlined label class="ml-2" :disabled="loading" title="Total dias">
          <v-icon class="mr-2" small>fas fa-calendar-days</v-icon>{{machine.production_unit_m2?Math.ceil(this.totalOnQueue.to_complete_m2/machine.capacity/machine.hours_per_day):Math.ceil(this.totalOnQueue.to_complete_qty/machine.capacity/machine.hours_per_day)}}
        </v-chip>
      </v-toolbar-title>
      <v-select
      v-model="filterEquipment"
      dense
      outlined
      hide-details
      label="Filtrar Equipo"
      :items="equipmentList"
      v-if="!dragEnable"
      :disabled="loading"
      />
      <v-spacer></v-spacer>
      <v-text-field 
        placeholder="Buscar" 
        class="justify-center"
        v-model="search"
        append-icon="fas fa-search"
        hide-details
        :disabled="loading"
        />
      <v-spacer></v-spacer>
      <v-btn small :color="dragEnable?'primary':'light-blue'" dark @click="addRegister" :disabled="loading" title="Agregar registro">
          <v-icon class="mr-2">fas fa-plus</v-icon> Registro
        </v-btn>
        <v-dialog 
          v-model='showCompleted' 
          :max-width="machine.hour_control?1200:800"
          transition="dialog-bottom-transition"
          >
          <v-card>
            <process-complete 
              :processes="completed" 
              :machine="machine"
              @refresh="getMachineData"
              @close="showCompleted=false"
            />
          </v-card>
        </v-dialog>
        <v-btn class="ml-2" v-if="dragEnable" small :color="dragEnable?'black':'light-blue'" dark @click="asignEquipment" :disabled="loading" title="Asignar Equipo">
          Asignar Equipo
        </v-btn>
        <v-dialog 
          v-model='showAsignEquipment' 
          max-width="600"
          
          >
          <v-card>
            <asign-equipment 
              :processes="toAsignEquipment" 
              :machine="machine"
              @refresh="getMachineData"
              @close="showAsignEquipment=false"
            />
          </v-card>
        </v-dialog>
    </v-toolbar>
    

    <!-- <v-text-field @input="estimar" class="mt-4" type='number' outlined label="Hora de Salida" v-model="endShift"></v-text-field> -->
    <v-card-text>
      <v-data-iterator
        :items="dragEnable?secuence:filteredSecuence"
        item-key="id"
        :items-per-page="-1"
        :search="search"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
        }"
        @current-items="detect"  
      >
        <template v-slot:default="{ items }">
          <v-simple-table>
          <thead>
            <tr>
              <th>
                <v-checkbox 
                v-model="allSelected"
                @change="toggleSelectAll"
                title="Seleccionar todo"
                />
              </th>
              <th class="table-header2 text-center">
                Secuencia
              </th>
              <th class="table-header2 text-center">
                OT
              </th>
              <th class="table-header2" width="10%">
                Cliente
              </th>
              <th class="table-header2" width="25%">
                Producto
              </th>
              <th class="table-header2">
                Proceso
              </th>
              <!-- <th v-if='machine.production_unit_m2' class="table-header2">
                Mts<sup>2</sup>
              </th>
              <th v-if='!machine.production_unit_m2' class="table-header2">
                Unidades
              </th> -->
             
              <th class="table-header2 text-end">
                Comprometida
              </th>
              <th class="table-header2 text-end">
                Estimada
              </th>
            </tr>
          </thead>
          <draggable 
            :disabled="!dragEnable"
            v-model="secuence"  
            @change="secuenceChange" 
            draggable=".recipe-row-move" 
            tag="tbody"
          >
            <tr 
              v-for="item in items" 
              :key="item.id" 
              :class="dragEnable?'recipe-row-move':''"
            >
              <td :class="item.end_date>item.delivery_date?'error--text':''" >
                <v-checkbox v-model='item.completed' title="Seleccionar para registro"/>
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':''" class="text-center">
                <div class="d-flex">
                  {{secuence.indexOf(item) + 1}}
                  <v-icon class="error--text ml-2" x-small v-if="item.reprocess">fas fa-clock-rotate-left</v-icon>
                </div>
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':''" class="" >
                <show-work-order  :work_order_id="item.work_order">{{item.work_order}}</show-work-order>
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':''">
                <p class="caption">{{item.customer_name}}</p>
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':''">
                <div v-if='item.product.library_product' class="text text-caption">
                  [{{item.product.library_product.sku}}]
                </div>
                <div>
                  {{item.product.name}}
                </div>
                
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':''" class="py-2">
                {{item.name}}
                <v-tooltip left v-if='machine.production_unit_m2'>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on">
                      <strong>{{item.completed_mts2_qty.mts2}}</strong> / {{item.product.mts2}}
                      <v-progress-linear
                          color="light-blue"
                          height="5"
                          :value="item.completed_mts2_qty.mts2/item.product.mts2*100"
                          striped
                        />
                    </div>
                  </template>
                  <bitacora-details @close='showBitacora=false' :process="item"/>
                </v-tooltip>
                <v-tooltip left v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on">
                      <strong >{{item.completed_mts2_qty.qty}}</strong> / {{item.product.qty}}
                      <v-progress-linear
                        color="light-blue"
                        height="5"
                        :value="item.completed_mts2_qty.qty/item.product.qty*100"
                        striped
                      />
                    </div>
                  </template>
                  <bitacora-details @close='showBitacora=false' :process="item"/>
                </v-tooltip>
                <v-chip class="mt-2" small v-show="item.equipmentAsigned_name">
                  {{item.equipmentAsigned_name}}
                </v-chip>
              </td>
              
              <td :class="item.end_date>item.delivery_date?'error--text':''">
                <div class="d-flex justify-end align-center" :class="item.end_date>item.delivery_date?'error--text':''">
                  {{item.delivery_date | moment("D/MM/YYYY")}}
                </div>
              </td>
              <td :class="item.end_date>item.delivery_date?'error--text':'success--text'">
                <span class="d-flex justify-end align-center"><v-icon small v-if='item.end_date>item.delivery_date' class="error--text mr-2">fas fa-exclamation-circle</v-icon>{{item.end_date | moment("D/MM/YYYY")}}</span>
              </td>
              <v-dialog 
                v-model='showBitacora' 
                max-width="600"
                hide-overlay
                >
                <v-card class='pa-4'>
                  <bitacora-details @close='showBitacora=false' :process="bitacoraDetails"/>
                </v-card>
              </v-dialog>
            </tr>
          </draggable>
        </v-simple-table>
        </template>
      </v-data-iterator>
      </v-card-text>
  </v-card>

</template>

<script>
import draggable from 'vuedraggable'
import ProcessComplete from '@/components/production/ProcessComplete'
import BitacoraDetails from '@/components/production/BitacoraDetails'
import ExportMachinePlanner from '@/components/production/ExportMachinePlanner'
import {getPendingProcesses,updatePlanner} from '@/api/production'
import notifications from '../../utils/notifications'
import AsignEquipment from '@/components/production/AsignEquipment'

export default {
  name:'MachinePlannerAlone',
  components:{draggable,ProcessComplete,BitacoraDetails,ExportMachinePlanner,AsignEquipment},
  props:['machine_id','mustSave','dragEnable'],
  data() {
    return {
      loading:false,
      saving:false,
      secuence: [],
      currentItems:[],
      endShift:18,
      search:'',
      machine:'',
      allSelected:false,
      showBitacora:false,
      showCompleted:false,
      showAsignEquipment:false,
      toAsignEquipment:[],
      bitacoraDetails:{},
      completed:[],
      filterEquipment:'Todos',
      headers:[
      { text: 'Secuencia', value: 'sec' },
      { text: 'Orden de Trabajo', value: 'product' },
      { text: 'Producto', value: 'workOrder' },
      { text: 'Proceso', value: 'process' },
      { text: 'Metros Cuadrados', value: 'mts2' },
      { text: 'Cantidad', value:'qty'},
      { text: 'Tiempo Est.',value:'estimatedTime'},
      { text: 'Fecha Entrega',value:'deliveryDate'},
      { text: 'Fecha Producción Est.',value:'estimatedDate'},
      ]
    }
  },
  methods:{
    detect(value){
      this.currentItems=value
    },
    async savePlanner(){
      this.saving=true
      this.machine.process_orders=this.secuence
      await updatePlanner([this.machine])
      .then(async (res)=>{
        notifications.showSuccess('Se guardaron los cambios en el planificador de '+this.machine.name)
        this.saving=false
        this.getMachineData()
      })
      .catch((e)=>{
          notifications.showError('Error Guardando Planificación '+e)
          this.saving=false
      })
    },
    estimar2(){
      const endShift=this.endShift
      const now = new Date()
      const minutes_left_today=endShift*60-(now.getHours()*60+now.getMinutes())
      const capacity = this.machine.capacity
      var minutes_qty=0
      this.secuence.forEach(process=>{
        const mts2=process.product.mts2 - process.completed_mts2_qty.mts2
        const qty=process.product.qty - process.completed_mts2_qty.qty
        var estimated_minutes=0

        if(this.machine.production_unit_m2){
          estimated_minutes=mts2/capacity*24*60
        }
        else estimated_minutes=qty/capacity*24*60

        this.$set(process, 'estimated_hours',  `${Math.floor(estimated_minutes/60)}h : ${parseInt((estimated_minutes/60-Math.floor(estimated_minutes/60))*60)}m`)
        minutes_qty+=estimated_minutes
        if(minutes_qty>minutes_left_today) minutes_qty +=(24-endShift)*60
        const estimate = new Date(new Date().setMinutes(new Date().getMinutes() + minutes_qty))
        if(estimate.getDay()==6){
          minutes_qty+=48*60
        }
        else if(estimate.getDay()==0){
          minutes_qty+=24*60
        }
 
        this.$set(process, 'end_date', new Date(new Date().setMinutes(new Date().getMinutes() + minutes_qty)).toISOString())
      })
    },
    estimar(){
      const now = new Date()
      const {capacity,hours_per_day,production_unit_m2,saturday,sunday,start_time} = this.machine
      
      var minutes_qty=0
      this.secuence.forEach(process=>{
        const mts2=process.product.mts2 - process.completed_mts2_qty.mts2
        const qty=process.product.qty - process.completed_mts2_qty.qty
       

        const estimated_hours=production_unit_m2?mts2/capacity:qty/capacity

        const estimated_minutes=(estimated_hours/hours_per_day)*24*60
        
        //Acumulo los dias
        minutes_qty+=estimated_minutes
        var end_date=new Date(new Date().setMinutes(new Date().getMinutes() + minutes_qty))

        //Tomo en cuenta los fines de semana
        if(end_date.getDay()==6 || end_date.getDay()==0){
          if(!saturday) minutes_qty+=24*60
          if(!sunday) minutes_qty+=24*60
          end_date=new Date(new Date().setMinutes(new Date().getMinutes() + minutes_qty))
        }

        //Seteo en el proceso
        this.$set(process,'estimated_hours',estimated_hours)
        this.$set(process, 'end_date', end_date.toISOString())
      })
    },
    secuenceChange(){
      this.estimar()
      this.$emit('update:secuence',this.secuence)
    },
    secuenceOrderFilter(){
      this.machine.secuence.map(item=>{
      const process = this.machine.process_orders.find(p=>p.id==item)
      if (process) this.secuence.push(process)
    })
    this.estimar()
    },
    getComponentData(){
      return {
        props: {
          items:this.secuence,
          class:"flat pa-4",
          search:this.search
        }
      };
    },
    toggleSelectAll(){
      this.currentItems.forEach(s=>{
        s.completed=this.allSelected
      })
    },
    addRegister(){
      this.completed=this.secuence.filter(proc=>proc.completed==true)
      this.completed.forEach(proc=>{
        proc.real_mts2=0
        proc.real_qty=0
        proc.ask_completed=false
      })
      if(this.completed.length>0) this.showCompleted=true
    },
    asignEquipment(){
      this.toAsignEquipment=this.secuence.filter(proc=>proc.completed==true)
      
      if(this.toAsignEquipment.length>0) this.showAsignEquipment=true
    },
    displayBitacora(item){
      this.bitacoraDetails=item
      this.showBitacora=true
    },
    async getMachineData(){
      this.secuence=[]
      this.loading=true
      await getPendingProcesses(this.machine_id)
      .then(res=>{
        this.machine=res
        this.machine.secuence.map(item=>{
          const process = this.machine.process_orders.find(p=>p.id==item)
          if (process) this.secuence.push(process)
        })
        this.secuence.map(item=>{
          item.work_order=item.product.work_order.id
          item.product_name=item.product.name
          return item
        })
        this.estimar()
        this.loading=false
      })
      .catch(err=>{
        notifications.showError(err)
        this.loading=false
      })
    }
  },
  mounted(){
      if(this.machine_id) this.getMachineData()
    },
  watch:{
    machine_id(){
      this.getMachineData()
    },
    secuence(){
      this.estimar()
    }
    
  },
  computed:{
    totalOnQueue(){
      const mts2 = this.secuence.reduce((total, item) => {
        return total + parseFloat(item.product.mts2)
      }, 0)
      const qty = this.secuence.reduce((total, item) => {
        return total + parseFloat(item.product.qty)
      }, 0)
      const mts2_completed = this.secuence.reduce((total, item) => {
        return total + parseFloat(item.completed_mts2_qty.mts2)
      }, 0)
      const qty_completed = this.secuence.reduce((total, item) => {
        return total + parseFloat(item.completed_mts2_qty.qty)
      }, 0)
      const to_complete_m2=mts2-mts2_completed
      const to_complete_qty=qty-qty_completed
      var production_days=0
      if (this.machine.production_unit_m2){
        production_days = (qty-qty_completed)/parseFloat(this.machine.capacity)
      }
      else{
        production_days = to_complete_m2/parseFloat(this.machine.capacity)
      }
      //const production_days=this.machine.production_unit_mt2 ? (to_complete_qty/parseFloat(this.machine.capacity)).toFixed(1):(to_complete_m2/parseFloat(this.machine.capacity)).toFixed(1)
      return {mts2,qty,mts2_completed,qty_completed, to_complete_m2, to_complete_qty,production_days} 
    },
    equipmentList(){
      return this.machine?['Todos',...this.machine.equipments.map(item=>item.name)]:[]
    },
    
    filteredSecuence(){
      return this.secuence.filter(item=>{
        if(this.filterEquipment!='Todos'){
          return (item.equipmentAsigned_name == this.filterEquipment)
        }
        else return true
      })
    },
  }
}
</script>

<style>
.recipe-row-move{
  cursor: move;
}
.bitacora-show{
  cursor:move;
}
.work-order-link{
  cursor:pointer;
  text-decoration: underline !important;
}

</style>