<template>
  <v-container>
    <v-row dense class="mb-4">
      <v-col cols="12" md="3">
        <h1 class="text text-h4 titulo">{{id>0?`Actualizar #${id}`:`Registrar Documento`}}</h1>
      </v-col>
      <v-col cols="12" md="9" align-self="center" class="justify-end d-flex align-center">
        <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
          <v-select
          dense
          class="text-end mr-2"
          v-model="dte.tipo"
          :items="tipos"
          item-text="text"
          item-value="value"
          label="Tipo de Documento"
          single-line
          hide-details
          outlined
          :error-messages="errors"
          />
        </ValidationProvider>
        <v-btn class="text-end" text @click.stop="$router.go(-1)"><v-icon>fas fa-chevron-left</v-icon></v-btn>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(confirm)" ref="formulario" @keydown="return event.key != 'Enter';">
        <v-card class="pa-4"> 
          <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model='work_order'
              label="Orden de Trabajo"
              outlined
              :append-icon="work_order_finded?'fas fa-check':'fas fa-search'"
              :loading="loadingWorkOrder"
              @keydown="searchWorkOrder"
              :hint="alertHasInvoices?['Ya existe un documento para esta orden de trabajo']:[]"
              :persistent-hint="alertHasInvoices"
              
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <ValidationProvider name="Documento" rules="required" v-slot="{ errors }">
              <v-text-field
                label="Nro Documento"
                v-model="dte.document_id"
                outlined
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col md="4">
            <ValidationProvider name="Documento" rules="required" v-slot="{ errors }">
              <v-text-field
                label="Fecha Emision"
                v-model="dte.date_emited"
                type="date"
                outlined
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>  
          <v-col cols="12" md="2" >
          <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="dte.rutRecep"
              label="RUT"
              dense
              :loading="loadingCustomer"
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
              :rules="[rules.rut]"
              @keyup="getCustomerDataByRut"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4" >
          <ValidationProvider name="Razon Social" rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              v-model="dte.rznSocRecep"
              label="Razón Social"
              :loading="loadingCustomer"
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4" >
          <ValidationProvider name="Giro" rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              v-model="dte.giroRecep"
              label="Giro"
              :loading="loadingCustomer"
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="2">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <v-text-field
                dense
                v-model="dte.payment"
                label="Condicion de Pago"
                type="number"
                :min="0"
                outlined
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <v-text-field
                dense
                v-model="dte.glosa"
                label="Referencia"
                :min="0"
                outlined
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              dense
              class="text-end mr-2"
              :items="contactList"
              :loading="loadingCustomer"
              :disabled="loadingCustomer"
              item-text="email"
              return-object
              label="Selecciona Contacto"
              single-line
              hide-details
              outlined
              @change="selectContact"
              >
          </v-select>
          </v-col>
          <v-col cols="12" md="4" >
          <ValidationProvider name="Contacto" rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              v-model="dte.contactRecep"
              label="Contacto" 
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col md="2">
            <v-select
              dense
              class="text-end mr-2"
              :items="addressList"
              :loading="loadingCustomer"
              :disabled="loadingCustomer"
              item-text="name"
              return-object
              label="Selecciona Direccion"
              single-line
              hide-details
              outlined
              @change="selectAddress"
              >
          </v-select>
          </v-col>
          
          <v-col cols="12" md="7" >
          <ValidationProvider name="Direccion" rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              label="Dirección" 
              v-model="dte.dirRecep"
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col cols="12" md="3" >
          <ValidationProvider name="Comuna" rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              label="Comuna"
              v-model="dte.cmnaRecep"
              :disabled="loadingCustomer"
              outlined
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          </v-col>
          <v-col> 
            <v-alert type="info" dense text v-if="alertCustomerNotInDB">Cliente No encontrado en Base de Datos</v-alert>
          </v-col>
        </v-row>
        </v-card>
        <v-card class="pa-4 mt-4">
          <v-row class="mt-4 align-center">
            <v-col cols="12" class="d-block">
              <div class="d-flex mb-4">
                <h1 class="text text-h5 titulo"><v-icon class="mr-2 align-items-center">fas fa-plus</v-icon>Productos</h1>
                <v-chip class="ml-4" info v-if="sales_note">Cotización <strong class="ml-2">{{sales_note}}</strong> <span v-if="sales_discount>0" class="ml-4">-- descuento de {{sales_discount}}% </span></v-chip>
              </div>
              <v-alert type="info" dense text v-if="alertProductsnotInDB">Existen productos no encontrados en la librería</v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
              :headers="headers"
              :items="dte.dte_detail"
              item-key="nro_linea"
              class=""
              hide-default-footer
              >
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    Agregue Elementos al documento
                  </v-alert>
                </template>
                <template v-slot:footer>
                  <v-row justify="center" align="center" class="mt-2">
                    <v-btn 
                    fab 
                    small 
                    color="success" 
                    class="mr-4"
                    @click="()=>dte.dte_detail.push({
                      nro_linea:dte.dte_detail.length + 1,
                      product:-1,
                      sku:'',
                      name:'',
                      unit:'',
                      qty:'',
                      price:''
                    })"
                    >
                      <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                  </v-row>
                </template>
                <template v-slot:item.sku="{ item }">
                  <ValidationProvider name="SKU" rules="required" v-slot="{ errors }">
                      <v-text-field   
                        v-model='item.sku'
                        :error-messages="errors"
                        :hide-details="errors.length<1"
                        solo
                        dense
                        style="width:100px"
                        @keyup="getProductDatabySKU(item)"
                        
                        >
                        <template v-slot:append-outer v-if="item.product<0&&item.sku!=''">
                          <v-icon x-small class="info--text">fas fa-exclamation</v-icon>
                        </template>
                      </v-text-field>
                  </ValidationProvider>
                </template>
                <template v-slot:item.name="{ item }">
                  <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                    <v-textarea  
                      v-model='item.name'
                      rows="2"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                      solo
                      dense
                      style="width:300px"
                      class="pa-2"
                      />
                    </ValidationProvider>
                </template>
                <template v-slot:item.qty="{ item }">
                  <ValidationProvider name="qty" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.qty'
                      :min="1"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                      solo
                      dense
                      style="width:100px"
                      ></v-text-field>
                    </ValidationProvider>
                </template>
                <template v-slot:item.unit="{ item }">
                  <ValidationProvider name="qty" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model='item.unit'
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                      solo
                      dense
                      style="width:80px"
                      ></v-text-field>
                    </ValidationProvider>
                </template>
                <template v-slot:item.price="{ item }">
                  <ValidationProvider name="qty" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.price'
                      :min="0"
                      solo
                      dense
                      style="width:150px"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template v-slot:item.total="{ item }">
                  {{item.price*item.qty | currency('$', 0)}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon class="error--text" small @click.stop="removeArticle(item)">fas fa-trash</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        
        <v-row class="justify-center mt-4">
        <v-col>
            <v-card class="py-6">
              <v-simple-table dense class='strip-table'>
                <thead>
                  <tr>
                    <th class="text-center table-header">Neto</th>
                    <th class="text-center table-header">IVA</th>
                    <th class="text-center table-header">Total</th> 
                    <th class="text-center table-header">Vencimiento</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center strip-body"><strong>{{neto| currency('$',0)}}</strong></td>
                    <td class="text-center strip-body d-flex align-center justify-center" style="min-height:80px;">
                      (<ValidationProvider name="IVA" rules="required" v-slot="{ errors }">
                        <v-text-field 
                        type="number" 
                        v-model="dte.iva" 
                        dense solo 
                        style="max-width:70px;" 
                        hide-details
                        max="100"
                        min="0"/>
                      </ValidationProvider>)%
                      <strong class="ml-4">{{(neto)*dte.iva*0.01| currency('$',0) }}</strong>
                    </td>
                    <td class="text-center strip-body"><strong class="">{{neto*(1+dte.iva/100)| currency('$',0)   }}</strong></td>
                    <td class="text-center strip-body"><strong class="">{{payment_date}}</strong></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
      </v-row>
        <v-row justify="center">
          <v-col class="text-center mt-4">
            <v-btn color="success" type="submit" :disabled="false" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            <!-- <v-btn color="success" @click="openFacturaCreate" :disabled="false" :loading="loading"><v-icon class="mr-2">fas fa-sd-card</v-icon>Open Factura</v-btn> -->
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog 
    v-model="showProgress"
    persistent
    max-width="500"
    >
      <v-card class="pa-10"> 
        <v-card-title class="titulo text-h4">Registrando...</v-card-title>
        <v-row>
          <v-col>
            <v-progress-linear v-model="progress" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
            <h1 class="text text-h4 titulo">{{progress | number('0.0')}}%</h1>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <confirm 
    v-model="showConfirm" 
    :message="dte.work_order?`Se creara un nuevo registro vinculado a la OT ${dte.work_order} `:'Se creará un nuevo registro sin vinculo a ninguna OT'" 
    @ok="create"
    @cancel="showConfirm=false"/>
  </v-container>
</template>

<script>
import {createDTE,getWorkOrderforDTE} from '@/api/salesNotes'
import {getCustomer,getCustomerByRUT} from '@/api/customers'
import {getProductbySKU} from '@/api/products'
import {fetchCompany} from '@/api/parameters'
import ProviderComplete from '@/components/stocks/ProviderComplete'
import AddArticle from '@/components/stocks/AddArticle'
import notifications from '@/utils/notifications'
import {validaRUT} from '@/utils/rut_validator'
import {createOpenFacturaDTE} from '@/api/openFactura'
import numeral from 'numeral'
import Vue from 'vue'
import moment from 'moment'

export default {
  name:'CreateDTE',
  components:{AddArticle,ProviderComplete},
  data() {
    return {
      loading: false,
      showConfirm:false,
      discount:0,
      rules:{
        rut:value=>validaRUT(value)||'Rut Inválido',
      },
      provider:'',
      provider_name:'',
      payment:'',
      currency:'',
      notes:'',
      title:'',
      progress:'',
      showProgress:false,
      id:-1,
      linkToWorkOrder:false,
      work_order:'',
      work_order_details:'',
      sales_note:'',
      loadingWorkOrder:false,
      work_order_finded:false,
      alertCustomerNotInDB:false,
      sales_discount:0,
      timer:'',
      tipos:[
        {
          value:30,
          text:'Factura'
        },
        {
          value:60,
          text:'Nota de Crédito'
        }
      ],
      headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'sku',
          show:false,
          class:'table-header'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
          show:true,
          class:'table-header'
        },
        {
          text: 'Unidad',
          align: 'start',
          sortable: false,
          value: 'unit',
          show:false,
          class:'table-header'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value: 'qty',
          width:'10%',
          show:true,
          class:'table-header'
        },
        {
          text: 'Precio Unit.',
          align: 'end',
          sortable: false,
          value: 'price',
          show:true,
          width:'12%',
          class:'table-header'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'total',
          show:true,
          class:'table-header'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          show:true,
          class:'table-header'
        },
        ],
      dte:{
        document_id:'',
        tipo:'',
        date_emited: new Date().toISOString().substr(0,10),
        customer:'',
        rutRecep:'',
        rznSocRecep:'',
        giroRecep:'',
        contactRecep:'',
        dirRecep:'',
        cmnaRecep:'',
        glosa:'',
        iva:parseInt(this.$store.getters.getCompany.tax),
        payment:0,
        dte_detail:[]
      },
      loadingCustomer:false,
      contactList:[],
      addressList:[],
      has_invoices:false,
    }
  },
  methods:{ 
    removeArticle(item){
      this.dte.dte_detail=this.dte.dte_detail.filter(i=>i.nro_linea!=item.nro_linea)
    },
    confirm(){
      //Valida que existan artículos en la OC
      if(this.dte.dte_detail.length<=0){
        notifications.showError('Deben existir elementos')
        return 0
      }
      this.showConfirm=true
    },
    save(){
      console.log('Guardando')
    },
    async getCustomerData(customer_id){
      this.loadingCustomer=true
      await getCustomer(customer_id)
      .then((data)=>{
        this.alertCustomerNotInDB=false
        this.dte.rutRecep=data.rut
        this.dte.rznSocRecep=data.name
        this.dte.giroRecep=data.giro
        this.dte.payment=data.payment
        this.contactList=data.contacts
        if(data.contacts.length>0) this.selectContact(data.contacts[0])
        this.addressList=data.addresses
        if(data.addresses.length>0) this.selectAddress(data.addresses[0])
        this.loadingCustomer=false
      })
      .catch(e=>{
        notifications.showError('Error cargando datos del cliente')
        this.loadingCustomer=false
      })
    },
    getCustomerDataByRut(){
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if(validaRUT(this.dte.rutRecep)){
          this.dte.rznSocRecep=''
          this.dte.giroRecep=''
          this.dte.payment=0
          this.dte.contactRecep=''
          this.dte.dirRecep=''
          this.dte.cmnaRecep=''
          await getCustomerByRUT(this.dte.rutRecep)
          .then((data)=>{
            this.alertCustomerNotInDB=false
            this.dte.rznSocRecep=data.name
            this.dte.giroRecep=data.giro
            this.dte.payment=data.payment
            this.contactList=data.contacts
            if(data.contacts.length>0) this.selectContact(data.contacts[0])
            this.addressList=data.addresses
            if(data.addresses.length>0) this.selectAddress(data.addresses[0])
            this.loadingCustomer=false
          })
          .catch(e=>{
            notifications.showInfo('Cliente no encontrado en BBDD')
            this.alertCustomerNotInDB=true
            this.loadingCustomer=false
          })

        }  
      }, 1000);
    },

    getProductDatabySKU(item){
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(async () => {
          
          await getProductbySKU(item.sku)
          .then((data)=>{
            item.product=data.id
            item.name=data.name
            item.price=data.price
            item.unit=data.sell_unit_m2?'MTS2': 'UN',
            item.qty=1
            item.price=data.price
          })
          .catch(e=>{
            item.product=-1
          })
      }, 1000);
    },

    selectContact(val){
      const {first_name, last_name} = val
      this.dte.contactRecep=`${first_name} ${last_name}`
    },
    selectAddress(val){
      const {street, number, comune} = val
      this.dte.dirRecep=`${street} ${number}`
      this.dte.cmnaRecep=comune
    },
    searchWorkOrder() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(async () => {
          this.loadingWorkOrder = true
          await getWorkOrderforDTE(this.work_order)
            .then((data)=>{
              
              this.work_order_finded=true 
              this.loadingWorkOrder=false
              const {customer,title,products,id,additional_costs,totals,sales_note,has_invoices}=data
              this.has_invoices=has_invoices
              this.sales_note=sales_note
              this.dte.customer=customer.id
              this.dte.rutRecep=customer.rut
              this.dte.rznSocRecep=customer.name
              this.dte.giroRecep=customer.giro
              this.dte.payment=customer.payment
              this.contactList=customer.contacts
              if(customer.contacts.length>0) this.selectContact(customer.contacts[0])
              this.addressList=customer.addresses
              if(customer.addresses.length>0) this.selectAddress(customer.addresses[0])
              this.dte.work_order=id
              this.dte.glosa=title
              this.sales_discount=totals.discount
              this.dte.dte_detail=[]
              products.forEach(p=>{
                this.dte.dte_detail.push({
                  nro_linea:this.dte.dte_detail.length + 1,
                  product:p.id,
                  sku:p.sku,
                  name:p.name,
                  unit:p.sell_unit_m2?'MTS2': 'UN',
                  qty:p.sell_unit_m2?p.totalMts2:p.qty,
                  price:this.$options.filters.number((this.$options.filters.number(p.price,'0')*(1-totals.discount*0.01)),'0'),
                })
              })
              additional_costs.forEach(c=>{
                this.dte.dte_detail.push({
                  nro_linea:this.dte.dte_detail.length + 1,
                  product:-1,
                  sku:c.name.toUpperCase().slice(0,4),
                  name:c.name,
                  unit:c.cost_unit,
                  qty:c.unit===1?totals.mts2:c.qty,
                  price:this.$options.filters.number((this.$options.filters.number(c.sell_price,'0')*(1-totals.discount*0.01)),'0'),
                })
              })
            })
            .catch((e)=>{
              if(this.dte.work_order) delete this.dte.work_order
              notifications.showInfo('Orden de Trabajo no encontrada')
              this.dte={
                document_id:'',
                tipo:'',
                date_emited: new Date().toISOString().substr(0,10),
                customer:'',
                rutRecep:'',
                rznSocRecep:'',
                giroRecep:'',
                contactRecep:'',
                dirRecep:'',
                cmnaRecep:'',
                glosa:'',
                iva:parseInt(this.$store.getters.getCompany.tax),
                payment:0,
                dte_detail:[]
              },
              this.sales_discount=0
              this.sales_note=''
              this.work_order_finded=false
              this.loadingWorkOrder=false
            })
      }, 800);
    },
    async create(){
      this.showConfirm=false
      if(this.dte.dte_detail.length<=0){
        notifications.showError('Deben existir items en el documento')
        return 0
      }
      if(!this.dte.customer) delete this.dte.customer
      this.dte.iva=parseInt(this.dte.iva)
      this.loading=true

      if(this.dte.tipo==''){
        notifications.showError('Debe seleccionar un tipo de documento')
        return true
      }

      //Cambia el precio al ser una NC
      if(this.dte.tipo==60){
        this.dte.dte_detail.forEach(d=>{
          d.price=-1*d.price
        })
      }

      await createDTE(this.dte)
      .then(res=>{
        this.$router.go(-1)
      })
      .catch(e=>{
        notifications.showError('Error creando documento')
        this.loading=false
      })
      this.loading=false
    },
    async openFacturaCreate(){
      await createOpenFacturaDTE()
      .then(res=>{
        console.log(res)
      })
      .catch(e=>{
        console.log(e)
      })
    } 
  },
  computed:{
    neto(){
      return this.dte.dte_detail.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    },
    payment_date(){
      return moment(this.dte.date_emited).add(this.dte.payment,'days').format('DD/MM/YYYY')
    },
    company(){
      return this.$store.getters.getCompany
    },
    alertProductsnotInDB(){
      return this.dte.dte_detail.filter(item=>{
        return item.product<0&&item.sku!=''
      }).length>0
    },
    alertHasInvoices(){
      return this.has_invoices
    },
  },
  async mounted(){
    this.company=await fetchCompany()

    if (this.$route.params.tipo){
      this.dte.tipo=this.$route.params.tipo
    }
    if(this.$route.params.duplicate){
      this.title='(Copia)'+this.title 
      this.id=-1
    }
    
  }
}
</script>

<style lang="scss" scoped>
.discount{
    width: 6rem !important;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
.titulo{
  color:#707070;
  font-size: medium !important;
}
.strip-body{
  padding: 0 !important;
  font-size: 22px !important;
}
</style>