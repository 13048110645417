<template>

  <v-container id="container">
    <div>
      <ValidationObserver v-slot="{handleSubmit}">
      <v-form @submit.prevent="handleSubmit(
        ()=>{
          if (work_order) showConfirmSaveWithWorkOrder=true;
        else save()
        })">
        <v-row class="mb-4 justify-space-between">
          <v-col class="">
            <h1 class="text-h4 titulo">{{id>0? `Cotización #${id}`:'Nueva Cotización'}}</h1>
            <div v-if='id>0' class="text mt-2">Creada por: <strong>{{salesNote.created_by_full_name}} - {{salesNote.created_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
            <div class="text" v-if="salesNote.updated_by" >Actualizada: <strong>{{salesNote.updated_by_full_name}} - {{salesNote.updated_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
          </v-col>
          <v-col align-self="top" class="text-center">
            <div class="d-flex d-inline-block-sm justify-end">
              <v-btn  v-if="$can('duplicate', 'SalesNote')" :disabled="id<0"  class="mx-2 secondary--text" @click="duplicate" text><v-icon class="mr-2">fas fa-clone</v-icon>Duplicar</v-btn>
              <v-btn 
              :loading="saveLoading" 
              color="success font-weight-black" 
              type="submit" class="mx-2"
              :disabled="(exist_product_below_cost)&&!$can('save_product_below_cost','SalesNote')"
              >
              <v-icon class="mr-2" >fas fa-sd-card</v-icon>
              Guardar
              </v-btn>
              <v-btn  @click.prevent="$router.push('/sales')" text ><v-icon>fas fa-chevron-left</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
          <v-card class="py-4 px-6">
            <v-row class="pt-4">
            <v-col cols="12" md="4">
              <customer-complete2 v-model='customer' :customer_name="customer.name" :status="true" />
              <div class="d-flex">
                <div class="text text-caption" v-if="customer">Vendedor: <strong>{{customer.seller.first_name}} {{customer.seller.last_name}}</strong> </div>
                <!-- <div class="text text-caption ml-4" v-if="customer">Cond. Pago: <strong>{{customer.payment==0?'Contado':customer.payment}} {{customer.payment!=0?'días':''}}</strong> </div> -->
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <ValidationProvider name="Contacto" rules='required' v-slot="{ errors }">
                <v-select
                  v-model="contact"
                  dense
                  :items="customer.contacts"
                  item-text="first_name"
                  item-value="id"
                  label="Contacto"
                  outlined
                  clearable
                  :disabled="!customer"
                  :error-messages="errors"
                >
                  <template v-slot:item="{ item }">
                      <span>
                        {{item.first_name + ' '+ item.last_name}}
                      </span>
                  </template>
                  <template v-slot:selection="{ item }">
                      <span>
                        {{item.first_name + ' '+ item.last_name}}
                      </span>
                  </template>
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="4">
              <ValidationProvider name="Nombre" rules='required|max:200' v-slot="{ errors }">
              <v-text-field 
              dense
              v-model='payment_condition'
              label='Condición de Pago'
              outlined
              clearable
              :error-messages="errors"
              ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="12">
              <ValidationProvider name="Nombre" rules='required|max:200' v-slot="{ errors }">
              <v-text-field 
              dense
              v-model='name'
              label='Proyecto' 
              outlined
              clearable
              :error-messages="errors"
              ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          </v-card>
      <v-card class="mt-6">
      <v-row justify="center">
        <v-simple-table class="pa-4 mb-6">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="titulo-2 text-center">
                Total Mts<sup>2</sup>
              </th>
              <th class="titulo-2 text-center" v-if="$can('readCost', 'SalesNote')">
                Costo
              </th>
              <th class="titulo-2 text-center">
                Valor Venta
              </th>
              <th class="titulo-2 text-center">
                Descuento
              </th>
              <th class="titulo-2 text-center">
                Total
              </th>
              <th class="titulo-2 text-center" v-if="$can('readCost', 'SalesNote')">
                Margen
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right strip-header">
                <strong>{{proyectMts2| number('0,0.00')}}</strong>
              </td>
              <td class="text-end strip-header" v-if="$can('readCost', 'SalesNote')"><strong class="">{{proyectCost | currency('$',0) }}</strong></td>
              <td class="text-end strip-header"><strong :class="proyectPrice>proyectCost?'':'red--text pa-2'" >{{proyectPrice | currency('$',0) }}</strong></td>
              <td>
                <div max-width="500px" class="d-flex align-center mr-4 text-right">
                  <v-text-field 
                    solo
                    dense
                    hide-details
                    class='discount mx-auto px-0 py-1'
                    type='number' 
                    append-icon="fas fa-percent"
                    v-model="discount"
                    @input="calculateProyectTotals"
                    :min="0"
                    :disabled="!$can('apply_discount', 'SalesNote')"
                  />
                  <strong class="ml-4 strip-header">({{proyectTotalDiscount | currency('$',0)}})</strong>
                </div>
              </td>
              <td>
                <strong :class="proyectTotal>proyectCost?'primary--text':'red--text pa-2'" class="strip-header">{{proyectTotal | currency('$',0) }}</strong>
              </td>
              <td v-if="$can('readCost', 'SalesNote')" class="text-right">
                <strong :class="proyectMargin>0?'primary--text':'red--text pa-2'" class="strip-header">{{proyectMargin | percent(1)  }}</strong>
              </td>
              
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-row>
    </v-card>  
        
          
        <v-row justify="center mt-6">
          <v-data-table
          :loading="loading"
          :headers="productsTableHeaders"
          :items="products"
          :single-expand="true"
          expanded.sync="expanded"
          expand-icon="fas fa-chevron-down"
          item-key="key"
          show-expand
          class="flex d-block elevation-1 pa-4"
          
          >
            <template v-slot:top>
              <v-row class="px-4" align="center">
                <v-col cols="12" md="8"> 
                  <div class="titulo-2 text-h5">Productos</div>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn :disabled="work_order_completed" class="my-3 primary--text" block @click="addingProduct=true" ><v-icon class="mr-2">fas fa-cart-plus</v-icon>Agregar Producto</v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                Agregue Productos
              </v-alert>
            </template>
            <template v-slot:item.name="{item}">
            <ValidationProvider name="Nombre" rules="required|max:200" v-slot="{ errors }">  
              <v-textarea
                :hide-details="!errors.length>0"
                rows="2"
                dense
                solo
                v-model="item.name"
                class="pa-2"
                :error-messages="errors"
                :disabled="work_order&&item.production_product>0"
              />
            </ValidationProvider> 
            </template>
            <template v-slot:item.qty="{item}">
              <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                <v-text-field 
                  solo
                  :hide-details="!errors.length>0"
                  class="product-quantity"  
                  dense 
                  type="number" 
                  :min="1"
                  v-model="item.qty"
                  :error-messages="errors"
                  :disabled="work_order&&item.production_product>0"
                  />
              </ValidationProvider>
            </template>
            <template v-slot:item.totalMts2="{item}">
              {{item.totalMts2 | number('0,0.00')}}
            </template>
            <template  v-slot:item.unitCost="{item}">
              <strong v-if="item.sell_unit_m2"  class="cost" color-text="primary">{{(item.totalCost/item.totalMts2) | currency('$',0)}}</strong>
              <strong v-else class="cost" color-text="primary">{{(item.totalCost/item.qty) | currency('$',0)}}</strong>
              <!-- <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong> -->
              <v-icon small class="ml-2">{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon> 
            </template>
            <template v-slot:item.totalCost="{item}">
              <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.price="{item}">
                <div class="d-flex">
                  <v-icon small class="mr-2 yellow--text" v-if="!item.is_margin&&item.price_bellow_original">fas fa-exclamation-triangle</v-icon>
                  <v-icon small class="mr-2 yellow--text" v-if="item.is_margin&&item.margin_bellow_original">fas fa-exclamation-triangle</v-icon>
                  <div class="d-block py-2">
                    <ValidationProvider name="Precio" rules="required" v-slot="{ errors }">
                      <v-text-field
                        class="" 
                        hide-details
                        type="number"
                        :min="0"
                        solo 
                        dense 
                        v-model="item.price"
                        :append-icon="item.sell_unit_m2 ? 'square_foot':'view_comfy'"
                        @click:append="item.sell_unit_m2 = !item.sell_unit_m2"
                        :error-messages="errors"
                        :readonly="item.margin_control"
                        />
                    </ValidationProvider>
                    <div class="d-flex mt-2">
                      <v-checkbox
                        v-if="item.is_margin"
                        v-model="item.margin_control"
                        hide-details
                        dense
                        />
                        <v-text-field
                            v-if="item.is_margin"
                            class="product-margin " 
                            hide-details
                            type="number"
                            solo 
                            dense
                            v-model="item.margin_price"
                            append-icon="percent"
                           
                            :readonly="!item.margin_control"
                            />
                    </div>
                  </div>
                  </div>
            </template>
            <template v-slot:item.totalPrice="{item}">
              <div :class="item.totalPrice>item.totalCost ? 'green--text':'red--text'">
                <strong>{{item.totalPrice | currency('$',0)}}</strong>
              </div>
            </template> 
            <template v-slot:item.actions="{item}">
              <v-icon small @click="duplicateProduct(item)" :disabled="work_order_completed">fas fa-clone</v-icon>
              <v-icon class="red--text ml-2" x-small @click="removeProduct(item)" :disabled='work_order&&item.production_product>0'>fas fa-trash</v-icon>
            
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-10">
                <v-row>
                <v-col cols="4">
                  <ValidationProvider name="Ancho" rules="required|minvalue:0.1" v-slot="{ errors }">
                    <v-text-field
                    class="short-text"
                    label='Ancho'
                    type="number"
                    :min="0"
                    v-model='item.width'
                    prepend-icon="straighten"
                    outlined
                    hint="Centímetros"
                    persistent-hint
                    :disabled="work_order&&item.production_product>0"
                    :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="4">
                  <ValidationProvider name="Alto" rules="required|minvalue:0.1" v-slot="{ errors }">
                    <v-text-field
                    class="ml-4 short-text"
                    type="number"
                    label='Alto'
                    :min="0"
                    v-model='item.height'
                    prepend-icon="height"
                    outlined
                    hint="Centímetros"
                    persistent-hint
                    :disabled="work_order&&item.production_product>0"
                    :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col align-self="center">
                  <number-label 
                    icon="fas fa-ruler-combined"
                    title="Área Metros Cuadrados"
                    >{{item.unitMts2 |number('0.000')}}
                  </number-label>
                </v-col>
              </v-row>
              <v-row>
                <recipe :mts2="item.totalMts2" :total="item.totalCost" :qty="item.qty" :items="item.product_receta" :disabled="work_order&&item.production_product>0"/>
              </v-row>
              <v-row>
                  <supplies :product_width="item.width" :product_height="item.height" :mts2="item.totalMts2" :total="item.totalCost" :qty="item.qty" :items="item.product_supplies" :disabled="work_order&&item.production_product>0"/>
              </v-row>
              <v-row>
                <v-col cols="12">
                    <ValidationProvider name="Observaciones" rules="max:200" v-slot="{ errors }">
                      <v-textarea
                      class="pa-6"
                      counter
                      filled
                      label="Observaciones"
                      rows="2"
                      v-model="item.notes"
                      :error-messages="errors"
                      ></v-textarea>
                      <!-- :disabled="work_order&&item.production_product>0" -->
                  </ValidationProvider>
                  <v-checkbox
                    v-model="item.not_show"
                    label="Esconder en cotizacion"
                    dense
                    />
                </v-col> 
              </v-row>
              </td>
            </template>
          </v-data-table>
        </v-row>
        
        <v-row justify="center" class="my-6">
          <v-data-table
          :loading="loading"
          :headers="proyectCostsHeaders"
          :items="proyectCosts"
          item-key="key2"
          class="flex d-block elevation-1 pa-4"
          hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" md="8">
                  <div class="titulo-2 text-h5 pa-4">Costos Adicionales</div>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="my-3 primary--text"  block @click="addingProyectCost=true" ><v-icon class="mr-2">fas fa-cart-plus</v-icon>Agregar Costo</v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                Agregue costos adicionales
              </v-alert>
            </template>
            <template v-slot:item.name="{item}">
              <div class="pa-2">
                <ValidationProvider name="Nombre" rules="required|max:200" v-slot="{ errors }">
                  <v-textarea 
                  :hide-details="!errors.length>0"
                  class="product-name" 
                  :error-messages="errors"
                  solo type="text" 
                  dense 
                  v-model="item.name" 
                  rows="2"/>
                </ValidationProvider>
              </div>
            </template>
            <template v-slot:item.qty="{item}">
                <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                  <v-text-field 
                    solo
                    :hide-details="!errors.length>0"
                    :disabled="item.unit==3||item.unit==1" 
                    dense type="number" 
                    :min="0"
                    v-model="item.qty"
                    :error-messages="errors"
                    />
                </ValidationProvider>
              
            </template>
            <template v-slot:item.unit="{item}">
               <v-chip label outlined  class="ml-1 chip">
                  {{item.cost_unit}}
              </v-chip>
            </template>
            <template  v-slot:item.unitCost="{item}">
              <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.totalCost="{item}">
              <strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
            </template>
            <template v-slot:item.price="{item}">
            <ValidationProvider name="Precio" rules="required" v-slot="{ errors }">
              <v-text-field
                class="product-price" 
                type="number"
                :min="0"
                :append-icon="item.unit.id==1?'square_foot':''"
                :hide-details="!errors.length>0"               
                solo 
                dense 
                v-model="item.sell_price"
                :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
            </template>
            <template v-slot:item.totalPrice="{item}">
              <div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
                <strong>{{item.totalPrice | currency('$',0)}}</strong>
              </div>
            </template> 
            <template v-slot:item.actions="{item}">
              <v-icon @click="deleteProyectCost(item)" x-small class="error--text">fas fa-trash</v-icon>
            </template>
          </v-data-table>
        </v-row>
        <v-row>
            <v-card class="grow">
              <v-card-title class="titulo-2 text-h5">Documentos</v-card-title>
              <v-card-text>
                <v-simple-table>  
                  <tbody>
                    <tr v-for="oldFile in oldFiles" :key="oldFile.url">
                      <td :class="oldFile.delete?'red lighten-5':''">
                          <a :href="oldFile.url" target="_blank">{{oldFile.name}}</a>
                      </td>
                      <td class="text-end red lighten-5" v-if="oldFile.delete">
                        <v-icon small class="info--text" @click="oldFile.delete=!oldFile.delete">fas fa-redo</v-icon>
                      </td>
                      <td class="text-end" v-else>
                        <v-icon x-small class="error--text" @click="oldFile.delete=!oldFile.delete">fas fa-trash</v-icon>
                      </td>
                    </tr>
                    <tr v-for="(file,index) in files" :key="index">
                      <td>
                        {{file.name}}
                      </td>
                      <td class="text-end">
                        <v-icon x-small @click="removeLocalFile(file)" class="error--text">fas fa-trash</v-icon>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-center">
                        <file-input @change="addFiles" class="pa-2 mt-2"/>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                
              </v-card-text>
            </v-card>
          
        </v-row>
        <v-row class="mt-2" justify="center" style="margin-bottom:120px">
          <v-col cols="12">
            <ValidationProvider name="Observaciones" rules="max:200" v-slot="{ errors }">
              <v-textarea
              counter
              dense
              class="pa-0"
              outlined
              label="Observaciones"
              auto-grow
              rows="4"
              v-model="notes"
              :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
          
        </v-row>
      </v-form>
      </ValidationObserver>
    </div>
    
    <v-dialog v-model="addingProduct" max-width="1100">
      <v-card class="pa-10">
        <h1 class="titulo text text-h4"><v-icon x-large>add_shopping_cart</v-icon> Agregar Productos <span v-if="customer.name"><v-icon>arrow_right_alt</v-icon> <strong>{{customer.name}}</strong></span></h1>
        <v-row>
          <v-col>
            <add-product @close="addingProduct=false" @add="addProduct" :customer='this.customer'/>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addingProyectCost" max-width="500">
      <cost-create-update
      :cost=newProyectCost 
      @save="addProyectCost"
      :custom='true'
      :global='true'
      />
    </v-dialog>
    <confirm 
    v-model="showConfirmSaveWithWorkOrder" 
    :message="'Al actualizar esta cotización se actualizará la Orden de Trabajo #'+work_order" 
    @ok="save"
    @cancel="showConfirmSaveWithWorkOrder=false"/>
  </v-container>
</template>

<script>
import CustomerComplete2 from '@/components/customers/CustomerComplete2'
import AddProduct from '@/components/products/products/AddProduct'
import Recipe from '@/components/products/products/Recipe'
import Supplies from '@/components/products/products/Supplies'
import notifications from '@/utils/notifications'
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import DeliveryEstimation from '@/components/sales/DeliveryEstimation.vue'
import {getProduct} from '@/api/products'
import {createSalesNote,getSalesNote,updateSalesNote,fetchStatus} from '@/api/salesNotes'
import {getCustomer} from '@/api/customers'
import {productCost} from '@/utils/productCost'
import NumberLabel from '@/components/utils/NumberLabel'
import FileInput from '@/components/utils/FileInput'
export default {
  name:'SalesNoteCreateUpdate',
  components:{CustomerComplete2,AddProduct,Recipe,Supplies,CostCreateUpdate,DeliveryEstimation,NumberLabel,FileInput},
  
  data:()=>({
    id:-1,
    customer:'',
    contact:'',
    name:'',
    products:[],
    proyectCosts:[],
    discount:0,
    notes:'',
    status:'',
    created_at:'',
    created_by:'',
    updated_at:'',
    updated_by:'',
    status_list:'',
    loading:false,
    saveLoading: false,
    addingProduct:false,
    addingProyectCost:false,
    newProyectCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:true,
      has_article:false,
      price_update_option:0
    },
    proyectCost:0,
    proyectMts2:0,
    proyectPrice:0,
    proyectTotalDiscount:0,
    proyectTotal:0,
    proyectMargin:0,
    salesNote:{},
    drawer:true,
    proyectCostsHeaders:[
      { text: 'Descripción', value: 'name' ,class:'table-header-dense'},
      { text: 'Cant.', value: 'qty',class:'table-header-dense' },
      { text: 'Unidad', value: 'unit' ,class:'table-header-dense'},
      { text: 'Cost. Unit.', value: 'unitCost' ,class:'table-header-dense'},
      { text: 'Cost. Total', value: 'totalCost',class:'table-header-dense' },
      { text: 'Prec. Unit.', value:'price',class:'table-header-dense'},
      { text: 'Prec. Total',value:'totalPrice',class:'table-header-dense'},
      { text: '', value: 'actions',class:'table-header-dense' },
    ],
    oldFiles:[],
    files:[],
    payment_condition:'',
    newFile:'',
    work_order:null,
    work_order_completed:false,
    showConfirmSaveWithWorkOrder:false,
  }),
  computed:{
    productsTableHeaders(){
      const header = [
      {text:'Código',value:'sku',class:'table-header-dense',available:true},
      { text: 'Desc.', value: 'name',class:'table-header-dense' ,available:true},
      { text: 'Cant.', value: 'qty' ,class:'table-header-dense product-quantity',align:'center', available:true},
      { text: 'Mts2', value: 'totalMts2' ,class:'table-header-dense',align:'end',available:true},
      { text: 'Costo', value: 'unitCost' ,class:'table-header-dense',align:'end',available:this.$can('readCost', 'SalesNote')},
      { text: 'Cost. Total', value: 'totalCost' ,class:'table-header-dense',align:'end',available:this.$can('readCost', 'SalesNote')},
      { text: 'Prec. Unit.', value:'price',class:'table-header-dense',align:'end',available:true,width:'180px'},
      { text: 'Prec. Total',value:'totalPrice',class:'table-header-dense',align:'end',available:true},
      { text: '', value: 'actions' ,class:'table-header-dense',available:true},
      { text: '', value: 'data-table-expand' ,class:'table-header-dense',available:true},
    ]
     return header.filter(item=>item.available)
    },
    exist_product_below_cost(){
      return this.products.some(item=>(item.totalCost/(item.sell_unit_m2?item.totalMts2:item.qty))>item.price)
    },
    exist_product_below_margin(){
      return this.products.some(item=>(item.margin_bellow_original))
    },
  },
  methods:{
    addFiles(files){
      this.files=[...this.files,...files]
    },
    removeLocalFile(file){
      this.files.splice(this.files.indexOf(file),1)
    },
    async addProduct(item){
      
      await getProduct(item.id)
      .then((product)=>{
        product.qty=1
        product.key=this.products.length +1
        product.notes=''
        product.original_price=product.price
        product.original_margin=product.margin_price
        product.price_bellow_original=false
        product.price_bellow_cost=false
        product.margin_bellow_original=false
        product.production_product=0
        if(product.is_margin) {
          product.margin_control=true
        }
        else{
          product.margin_control=false
        }
        this.$set(this.products,this.products.length,product)
      })
      .catch((e)=>{
          notifications.showError('Error cargando el producto' + e)
      })
    },
    removeProduct(item){
      const index=this.products.indexOf(item)
      this.products.splice(index, 1);
      //Correccion del key de los productos
      this.products.forEach((item,index)=>{
        item.key=index+1
      })
    },
    duplicateProduct(item){
      //solucion cochina para copiar un objeto sin referencia
      const newItem= JSON.parse(JSON.stringify(item))
      
      this.$set(this.products,this.products.length,{...newItem,production_product:0, key:this.products.length +1})
    }, 
    totals(){
      console.log('Calculando totals')
      this.loading=true
      //Calculo de totales por producto
      this.products.forEach(product=>{
        const productCostResults=productCost(product)
        const unit = productCostResults.unit
        const total = productCostResults.total
        const mts2 = productCostResults.totalMts2 
        const unitMts2=productCostResults.mts2 
        product.qty=parseInt(product.qty)||1
        //Calculo de precio en caso que el producto tenga calculo con margen
        if (product.is_margin && product.margin_price!=0 && product.margin_control){
          product.price=(unit/(1-product.margin_price/100)).toFixed(2)
          product.sell_unit_m2=false
        }
        if (product.is_margin && product.margin_price!=0 && !product.margin_control){
          product.margin_price=100*(1-unit/product.price)
          product.sell_unit_m2=false
        }
        //const totalPrice = product.sell_unit_m2 ? product.price*mts2 : product.price*product.qty
        //Uso alternativa para redondear el precio unitario antes de multiplicar
        const totalPrice = product.sell_unit_m2 ? this.$options.filters.number(product.price,'0.00')*mts2 : this.$options.filters.number(product.price,'0.00')*product.qty

        this.$set(product, 'unitCost', unit)
        this.$set(product,'totalCost',total)
        this.$set(product,'totalMts2',mts2)
        this.$set(product,'unitMts2',unitMts2)
        this.$set(product,'totalPrice',totalPrice)

        //Coloca los flags de acuerdo al precio y costo
        this.$set(product,'price_bellow_original',parseFloat(product.price)<parseFloat(product.original_price))
        this.$set(product,'price_bellow_cost',parseFloat(product.totalPrice)<parseFloat(product.totalCost))
        this.$set(product,'margin_bellow_original',parseFloat(product.margin_price)<parseFloat(product.original_margin))

      })
      this.calculateProyectTotals()
      this.loading=false
    },
    calculateProyectTotals(){
      var proyectCost = 0
      var proyectMts2 = 0
      var proyectPrice = 0
      var totalDiscount = 0
      var proyectTotal = 0
      var proyectMargin = 0
      
      //Suma a los totales los costos por producto
      this.products.map(product=>{
        proyectCost  +=product.totalCost
        proyectMts2 +=product.totalMts2
        proyectPrice +=product.totalPrice
      })

      //Suma costos adicionales del Proyecto
      this.proyectCosts.map(cost => { 
        proyectCost+=cost.totalCost
        proyectPrice +=cost.totalPrice 
      })
      //Calcula los totales con el descuento
      totalDiscount = proyectPrice*this.discount/100
      proyectTotal=proyectPrice-totalDiscount
      //Calcula el Margen
      proyectMargin=1-proyectCost/proyectTotal
      
      this.proyectCost = proyectCost
      this.proyectMts2 = proyectMts2
      this.proyectPrice = proyectPrice
      this.proyectTotalDiscount = totalDiscount
      this.proyectMargin = proyectMargin
      this.proyectTotal=proyectTotal
    },
    calculateProyectCosts(){
      this.loading=true
      this.proyectCosts.map(cost => {
        var unitCost = 0
        var totalCost = 0
        var totalPrice = 0
        if(cost.unit==1){
          //Verifica si la unidad es por m2
          unitCost = cost.price
          totalCost=unitCost*this.proyectMts2
          totalPrice=cost.sell_price*this.proyectMts2 
        }
        else{
          unitCost = cost.price
          totalCost=unitCost*cost.qty
          totalPrice=cost.sell_price*cost.qty
        }
        this.$set(cost,'unitCost',unitCost)
        this.$set(cost,'totalCost',totalCost)
        this.$set(cost,'totalPrice',totalPrice)  
      })
      //Recalcula los totales por proyecto
      this.calculateProyectTotals()
      this.loading=false
    },
    async save(){
      //Chequeo que todos los productos tengan al menos un proceso
      var error_process_length=false
      this.products.map(product=>{
        if (product.product_receta.length<1) {
          error_process_length=true
        }
      })
      if (error_process_length){
        notifications.showError("Los productos tienen que poseer al menos un proceso")
        return 0
      } 
      //---------------------------------------------------------
      //Chequeo si el producto escondido debe tener precio 0
      var error_price_hidden=false
      this.products.map(product=>{
        if (product.not_show && product.price>0) {
          error_price_hidden=true
        }
      })
      if (error_price_hidden){
        notifications.showError("Los productos escondidos deben tener precio 0")
        return 0
      }
      //---------------------------------------------------------
      
      this.saveLoading=true

      var formData=new FormData()
        formData.append('customer', this.customer.id)
        formData.append('contact', this.contact)
        formData.append('name', this.name)
        formData.append('payment_condition', this.payment_condition)
        formData.append('details', JSON.stringify({
          products:this.products,
          costs:this.proyectCosts,
          notes:this.notes
        }))
        formData.append('totals', JSON.stringify({
          cost: this.proyectCost,
          mts2: this.proyectMts2,
          price: this.proyectPrice,
          discount: this.discount || 0,
          totalDiscount:this.proyectTotalDiscount,
          margin:this.proyectMargin,
          total: this.proyectTotal
        }))
        this.files.forEach(file => {
          formData.append(`file${this.files.indexOf(file)}`, file)
        })
        

      if(this.id>0){
        //Creando objeto para actualizar
        //Agrego lista de archivos antiguos para revisar cual eliminar
        formData.append('oldFiles', JSON.stringify(this.oldFiles))

        await updateSalesNote(this.id,formData)
        .then(()=>{
          this.$router.push('/sales')
          notifications.showSuccess('Cotización Guardada con Exito!')
          this.saveLoading = false
        })
        .catch((e)=>{
          notifications.showError('Error Actualizando Cotización '+e)
          this.saveLoading = false
        })
      }
      else{
        //considera que es una nueva nota
        await createSalesNote(formData)
        .then(()=>{
          this.$router.push('/sales')
          notifications.showSuccess('Cotización Creada con Exito!')
          this.saveLoading = false
        })
        .catch((e)=>{
          notifications.showError('Error Cotización '+e)
          this.saveLoading = false
        })
      }
    },
    //Función para añadir costos adicionales al proyecto
    addProyectCost(item){
      console.log(item)
      const cost={
        id:item.id,
        price:item.price,
        sell_price:item.price,
        unit:item.unit,
        cost_unit:item.unit_name,
        name: item.name,
        qty:1,
      }
      this.proyectCosts.push(cost)
      this.newProyectCost={
        id:-1,
        unit:'',
        name:'',
        price:0,
        min_price:0,
        is_supply:true
      }
      this.addingProyectCost=false
    },
    deleteProyectCost(item){
      const index = this.proyectCosts.indexOf(item);
      this.proyectCosts.splice(index, 1);
    },
    //Duplica la NV presente
    duplicate(){
      this.$router.push({name:'SalesNoteCreateUpdate',params:{id:this.id, duplicate:true}})
    },
  },
  watch:{
    //Revisa cambios en producto para activar calculo de totales
    products:{
      deep: true,
      handler: 'totals'
    },
    //Revisa cambios en proyecto para activar el calculo de totales
    proyectCosts:{
      deep: true,
      handler: 'calculateProyectCosts'
    },
    //Revisa cambios en cantidad de Mts2 para recalcular costos
    proyectMts2:{
      handler: 'calculateProyectCosts'
    },
    customer(){
      this.payment_condition=this.customer.payment==0?'Contado':`${this.customer.payment} días`
    },
    
  },
  async mounted(){
    if(this.$route.params.id){
      this.loading=true
      await getSalesNote(this.$route.params.id)
      .then(async salesNote=>{
        if(salesNote.customer){
          await getCustomer(salesNote.customer)
          .then((customer)=>{
            this.customer = customer
          })
        }
        //Guardo la nota de venta completa para usar para PDF y otros comandos
        this.salesNote = salesNote

        //Llevo los datos al template

        this.contact=salesNote.contact
        this.id=salesNote.id
        this.name=salesNote.name
        this.payment_condition=salesNote.payment_condition
        this.products=salesNote.details.products
        this.notes=salesNote.details.notes
        this.discount=salesNote.totals.discount
        this.status=salesNote.status
        this.created_at=salesNote.created_at
        this.created_by=salesNote.created_by.first_name + ' ' + salesNote.created_by.last_name
        this.updated_at=salesNote.updated_at
        this.updated_by=salesNote.created_by.first_name + ' ' + salesNote.created_by.last_name
        this.proyectCosts=salesNote.details.costs
        this.work_order=salesNote.work_order
        this.work_order_completed=salesNote.work_order_completed
        this.oldFiles=salesNote.files.map(f=>{
          return {
            ...f,delete:false
          }
        })
      })
    }
    //Verifica si quiero duplicar una nota de venta
    //y establezco el id en estado de nueva nota
    if(this.$route.params.duplicate){
      this.name='(Copia) '+this.name 
      this.id=-1
      this.work_order=null
      //Establezco el estado de la nota en pendiente
      this.status='pending'
      //Si duplico vuelvo a establecer los production_product en 0
      this.products=this.products.map(product=>{
        product.production_product=0
        return product})
    }
    // this.status_list=await fetchStatus()
  }
}

</script>

<style lang="scss" scoped>
  
  .discount{
    width: 6rem !important;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
  .product-quantity {
    width: 100px;
    display: flex-end;
  }
  .v-text-field__slot input{
    text-align: right;
    color: primary;
  }
  .product-price{
    width: 8rem;
    font-size: 14px;
    font-weight: bold;
    max-height: fit-content;
    
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
  .product-name {
    width: 10rem;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
  .cost{
    color: primary;
  }
  .bottom-float{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 130px;
    z-index: 1;
  }
  #content{
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -130px;
  }
  .strip-header{
    font-size:medium !important;
  }
  .strip-table{
    
    td{
      border-left: 0.1rem solid grey;
    }
  }
  .file-input{
    border-color: red !important;
    border-width: 0.1rem !important;
    display: flex !important;
    
    align-items: center ;
  }
</style>